import React from 'react';

function Speakers() {
    return ( 
       
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', paddingTop: 50, paddingBottom: 50, flexDirection: 'column'}}>
      <h1>Coming soon ...</h1>
    </div>

//       <div className="page-wrapper">
//   {/* Preloader */}
//   <div className="preloader" />
  
//   {/* Hidden bar back drop */}
//   <div className="form-back-drop" />
 
//   {/*Page Title*/}
//   <section className="page-title" style={{backgroundImage: 'url(images/background/spek.png)'}}>
//     <div className="anim-icons full-width">
//       <span className="icon icon-bull-eye" />
//       <span className="icon icon-dotted-circle" />
//     </div>
//     <div className="auto-container">
//       <div className="title-outer">
//         <h1>Speakers</h1>
//         <ul className="page-breadcrumb">
//           <li><a href="/">Home</a></li>
//           <li>Speakers</li>
//         </ul> 
//       </div>
//     </div>
//   </section>
//   {/*End Page Title*/}
//   {/* Speakers Section */}
//   <section className="speakers-section">
//     <div className="anim-icons full-width">
//       <span className="icon icon-dotted-circle" />
//     </div>
//     <div className="auto-container">
//       <div className="sec-title text-center">
//         <span className="sub-title">Our SPEAKERS</span>
//         <h2>Speakers Who Are Exparts <br />in Their Fields</h2>
//         <span className="divider" />
//       </div>
//       <div className="row">
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="/speakerdetails_1"><img src="images/clients/c6.jpeg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">Mourad Abed</a></h4>
//               <span className="designation">Vice-Président Uphf</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">yamina</a></h4>
//               <span className="designation">  Co-Founder</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">sara</a></h4>
//               <span className="designation">  CEO</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="900ms">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">mouna</a></h4>
//               <span className="designation">  Manager</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">yamina</a></h4>
//               <span className="designation">Nnnn</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">sara </a></h4>
//               <span className="designation"> aaa</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms">
//           <div className="inner-box">
//             <div className="image-box">
//               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">yamina</a></h4>
//               <span className="designation"> Manager</span>
//             </div>
//           </div>
//         </div>
//         {/* Speaker block */}
//         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="900ms">
//           <div className="inner-box">
//             <div className=" image-box">
//               <figure className="image image-2"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
//               <span className="plus-icon fa fa-plus" />
//               <div className="social-links">
//                 <a href="#"><i className="fab fa-linkedin" /></a>
//                 <a href="#"><i className="fab fa-facebook-f" /></a>
//                 <a href="#"><i className="fab fa-twitter" /></a>
//                 <a href="#"><i className="fab fa-pinterest-p" /></a>
//               </div>
//             </div>
//             <div className="info-box">
//               <h4 className="name"><a href="speaker-detail.html">sarra</a></h4>
//               <span className="designation">bbb</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
//   {/* End Speakers Section */}
 
 
// </div>



    
    );
}

export default Speakers;
