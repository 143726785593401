
import Footer from './layouts/Footer';

import { Route, Routes } from 'react-router-dom';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Authors from './pages/Authors';
import Travelo from './pages/Travelo';
import Navbar from './layouts/Navbar';
import Register from './pages/Register';
import Coomitee from './pages/Coomitee';
import SpeakerDetails from './pages/SpeakerDetails';
import Speakers from './pages/Speakers';
import Navmobile from './layouts/Navmobile';

function App() {
  return (
    <div className="App">

      <Navbar />
      <Navmobile />
      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/commite' element={<Coomitee />} />
        <Route path='/SpeakerDetails_1' element={<SpeakerDetails />} />
      {/* <Route path='/commitedetails_2' element={<SpeakerDetails2 />} />
        <Route path='/commitedetails_3' element={<SpeakerDetails3 />} /> */}
 <Route path='/speakers' element={<Speakers />} />
        <Route path='/register' element={<Register />} />
        <Route path='/authors' element={<Authors />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/travel' element={<Travelo />} />

      </Routes>

      <Footer />
    </div>
  );
}

export default App;
