import React from 'react'

function Contact() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/94.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Contact</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="map-section " style={{ marginTop: "150px" }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="contact-column col-lg-5 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="sub-title">QUICK CONTACT</span>
                                    <h2>Need Help? Contact Us</h2>
                                    <span className="divider" />
                              
                                </div>
                                <ul className="contact-info">
                                    <li>
                                        <span className="icon fa fa-map-marker-alt" />
                                        <h4>Our Location:</h4>
                                        <p>Valenciennes , France</p>
                                    </li>
                           
                                    <li>
                                        <span className="icon fa fa-envelope" />
                                        <h4>Email:</h4>
                                        <p><a href="mailto:newyork@example.com">icmaie.conf@uphf.fr</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Form Column */}
                        <div className="map-column col-lg-7 col-md-12 col-sm-12">
                            {/*Map Outer*/}
                            <div className="map-outer">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2547.560757773491!2d3.510496476089737!3d50.31878859683558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2ed2777ed25f3%3A0x9e55f3861a5ef7bb!2sInstitut%20des%20Mobilit%C3%A9s%20et%20des%20Transports%20Durables!5e0!3m2!1sfr!2sfr!4v1717669188828!5m2!1sfr!2sfr" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Contact