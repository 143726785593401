import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Navmobile() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        console.log("Menu button clicked");
        setIsOpen(!isOpen);
        console.log("Menu state:", !isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('mobile-menu-visible');
        } else {
            document.body.classList.remove('mobile-menu-visible');
        }

        // Nettoyage au démontage du composant
        return () => {
            document.body.classList.remove('mobile-menu-visible');
        };
    }, [isOpen]);

    return (
        <div>
            {/* Mobile Header */}
            <div className="mobile-header">
                <div className="logo">
                    <a href="index.html">
                        <img src="images/logo.png" alt="logo" title="Logo" />
                    </a>
                </div>
                <div className="nav-outer clearfix">
                    <div className="outer-box">
                        {/* Search Btn */}
                        <div className="search-box">
                        
                        </div>
                        {/* Mobile Menu Toggler */}
                        <button onClick={toggleMenu} className="mobile-nav-toggler navbar-trigger">
                            <i className="flaticon-menu" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={toggleMenu} />
                <nav className="menu-box">
                    <div className="upper-box">
                        <div className="nav-logo">
                            <a href="index.html">
                                <img src="images/logo.png" alt="logo" title="Logo" />
                            </a>
                        </div>
                        <button className="close-btn" onClick={toggleMenu}>
                            <i className="icon flaticon-close" />
                        </button>
                    </div>
                    {/* Mobile Navigation Menu */}
                    <ul className="navigation clearfix">
                        <li><a href="/">Home</a></li>
                        <li><a href="/commite">Committees</a></li>
                        <li className={location.pathname === '/speakers' ? 'current' : ''}>
                            <a href="/speakers">Speakers</a>
                        </li>
                        <li className={location.pathname === '/authors' ? 'current' : ''}>
                            <a href="/authors">Authors guidelines</a>
                        </li>
                        <li><a href="/register">Registration</a></li>
                        <li className={location.pathname === '/travel' ? 'current' : ''}>
                            <a href="/travel">Travel</a>
                        </li>
                        <li className={location.pathname === '/contact' ? 'current' : ''}>
                            <a href="/contact">Contact</a>
                        </li>
                    </ul>
                    <ul className="contact-list-one">
                        <li>
                            <i className="flaticon-location" /> Valenciennes, France 
                        </li>
                        <li>
                            {/* <i className="flaticon-alarm-clock-1" />Wednesday - Friday 9am - 6pm <strong>Timing</strong> */}
                        </li>
                        <li>
                            <i className="flaticon-email-1" />
                            <a href="mailto:envato@gmail.com">icmaie.conf@gmail.com</a>  
                        </li>
                    </ul>
                
                </nav> 
            </div>
        </div>
    );
}

export default Navmobile;
