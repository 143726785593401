import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function Navbar() {
    const location = useLocation();
      return (
        <>
          <header className="main-header header-style-one">
                {/* Header top */}
                <div className="header-top">
                    <div className="auto-container">
                        <div className="inner-container">
                            <div className="top-left">
                        </div>
                            <div className="top-right">
                                <ul className="contact-list-one">
                                    <li><i className="flaticon-location" /> Valenciennes, FRANCE  </li>
                                    {/* <li><i className="flaticon-alarm-clock-1" />Wednesday - Friday 9am - 6pm <strong>Timeing</strong></li> */}
                                    <li><i className="flaticon-email-1" /> <a href="mailto:envato@gmail.com">icmaie.conf@uphf.fr</a></li>
                                </ul>
                              {/* <div className="time-counter-two animate-4">
                                     <div className="time-countdown" data-countdown="10/06/2025"></div> 
                              </div>  */}                           
                            </div> 
                        </div>
                    </div>
                </div>
                {/* End Header Top */}
                {/* Header Lower */}
                <div className="header-lower">
                    <div className="auto-container">
                        {/* Main box */}
                        <div className="main-box">
                            <div className="logo-box">
                                <div className="logo"><a href="/">
                                  <img src="images/logo.png" alt title style={{ width: "120px", height: "120px", padding: "-10" }} /></a>
                                </div>
                            </div>
                            <div className="nav-outer">
                                {/* Main Menu */}
                                <nav className="main-menu navbar-expand-md">
                                    <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li ><a href="/">Home</a></li>
                                          <li className={location.pathname === '/commite' ? 'current' : ''}><a href="/commite">Committees</a>
                                            </li>
                                            <li className={location.pathname === '/speakers' ? 'current' : ''}><a href="/speakers">Speakers</a>
                                            </li>
                                            <li className={location.pathname === '/authors' ? 'current' : ''}><a href="/authors">Authors guidelines</a>
                                            </li>
                                            <li  ><a href="/register">Registration</a>
                                            </li>
                                            <li className={location.pathname === '/travel' ? 'current' : ''}><a href="/travel">Travel</a>
                                            </li>
                                            <li className={location.pathname === '/contact' ? 'current' : ''}><a href="/contact">Contact</a></li>
                                        </ul>
                                    </div>
                                </nav>
                                {/* Main Menu End*/}
                            </div>
                            <div className="logo-box"> <div className="logo"><a href="/"><img src="images/clients/6.png" alt title style={{ width: "120px", height: "120px", padding: "-10" }} /></a></div> </div>
                        </div>
                    </div>
                </div>
         </header>

        </>
    )
}

export default Navbar