import React from 'react'

function SpeakerDetails() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/spek.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Speakers</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">conference</a></li>
                            <li>Speakers Details</li>
                        </ul>
                    </div>
                </div> 
            </section>

            <section className="speaker-detail-section">
                <div className="auto-container">
                    <div className="row">
                        <div className="image-column col-lg-7 col-md-12 col-sm-12">
                            <div className="row">
                                {/* Speaker block */}
                                <div className="speaker-block col-lg-5 col-md-6 col-sm-12 wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><a href="#"><img src="images/clients/c6.jpeg" alt /></a></figure>
                                            <span className="plus-icon fa fa-plus" />
                                            <div className="social-links">
                                                <a href="#"><i className="fab fa-linkedin" /></a>
                                                <a href="#"><i className="fab fa-facebook-f" /></a>
                                                <a href="#"><i className="fab fa-twitter" /></a>
                                                <a href="#"><i className="fab fa-pinterest-p" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-column col-lg-7 col-md-6 col-sm-12">
                                    <div className="speaker-details">
                                        <h4 className="name"><a href="#">Mourad Abed</a></h4>
                                        <span className="designation"> Vice president uphf</span>
                                        <ul className="speaker-info-list">
                                            <li><strong>Speciality:</strong> AI</li>
                                            <li><strong>Company:</strong> Université pplytechnique hauts de france</li>
                                            <li><strong>Experience:</strong> 15 Years</li>
                                            <li><strong>Email:</strong> <a href="mourad.abed@uphf.fr">mourad.abed@uphf.fr</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-column col-lg-5 col-md-12 col-sm-12">
                            <h3>Personal Information</h3>
                            <p>Mouras assumed the role of Chief Product Officer in July 2017 and leads the product team, which designs, builds and optimizes the Netflix experience. Previously, Anna was International Development Officer for Netflix, responsible for the global partnerships with consumer electronics.</p>
                            <p>Prior to joining Netflix in 2008, Anna was senior vice president of consumer electronics products for Macrovision Solutions Corp. (later renamed to Rovi Corporation) and previously held positions at digital entertainment software provider, Mediabolic Inc., Red Hat Network, the provider of Linux and Open Source technology, and online vendor Wine.</p>
                        </div>
                    </div>
                    <div className="other-info">
                        <h2>Speeches by Mourad Abed</h2>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="speaker-session-info">
                                    <div className="inner-box">
                                        <h4>[Day 1]</h4>
                                        <span className="time"> 12.00 am - 01.00 pm </span>
                                        <p><span className="place">Place: </span> .........................</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="speaker-session-info">
                                    <div className="inner-box">
                                        <h4>[Day 2]</h4>
                                        <span className="time"> 01.00 am - 02.00 pm </span>
                                        <p><span className="place">Place: </span> ...................</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="speaker-session-info">
                                    <div className="inner-box">
                                        <h4>[Day 3]</h4>
                                        <span className="time"> 01.00 am - 02.00 pm </span>
                                        <p><span className="place">Place: </span>  ............</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

















        </>
    )
}

export default SpeakerDetails